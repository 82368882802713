import revive_payload_client_T0W7_M_Qcx1hpelZ7oZHKx3o98g7esNMZZbXmsxDv1M from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZcVccXOmJOWRrJSQP4X1UZ9KBGZf0S_3WSz_gRCghzQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vIdeHoEPs4ewKIDjTkgyOGhd8RIkRZWDfl9Lhs8JHRw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_IF9znHNEGXtydddk5Gh6_HWjts0JxB7dF2A_RTn4BUA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BZQm9VaIneSHQ4HECL4a3XnTGcNztWvaWE1qTQfw4lE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_roW1anLEcKgCsymBdh0Y1vKNZ2H0C1nzbq4m_ZhqYLY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6KuUVAbsf_XjcO4d4wdy0vjUy7F1goxolp4U1exTchQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_0LdmbEwaZlmez_pLMjoiNzaQjnu46_jB7rmnKP9lUE4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_ioredis@5.6.0_magicast@_1cf02ee297ca8bb80575325bd7bd60ce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NcviW38rpSD2IsuLJzzNVEF2WiSFKuUcUjSV5jfasuQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_sass@1.55.0__072c888d0b821739bf7438c552a1667d/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_M4MFWlizSatyVIUowK4VJc1Ur_7uSp9S9M3HGzLMZzc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import siteConfig_HWRVSH8PoXdN2sdoM1aq6aAH9RFTjnDvOsJRDHPHtaA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.5_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_6VdRUTP2HRvZp7dLS0vD5lQKXygcaJRDdLEGeassXfI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.5_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_qhTD2pN7QljOO0ZbjV5Hs9i5ciO4An4563f8rf4gpCk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.5_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _0_routeRules_1MFZdZ2iCDne8e_P5mMTA8GbtN42x2s_44JFwLQXgb8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.5_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_KNAcQuIU9c6xfB_Yvxb9204wHbUsE_FRV4nziaeJ_o0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.5_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_T0W7_M_Qcx1hpelZ7oZHKx3o98g7esNMZZbXmsxDv1M,
  unhead_ZcVccXOmJOWRrJSQP4X1UZ9KBGZf0S_3WSz_gRCghzQ,
  router_vIdeHoEPs4ewKIDjTkgyOGhd8RIkRZWDfl9Lhs8JHRw,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_IF9znHNEGXtydddk5Gh6_HWjts0JxB7dF2A_RTn4BUA,
  navigation_repaint_client_BZQm9VaIneSHQ4HECL4a3XnTGcNztWvaWE1qTQfw4lE,
  check_outdated_build_client_roW1anLEcKgCsymBdh0Y1vKNZ2H0C1nzbq4m_ZhqYLY,
  chunk_reload_client_6KuUVAbsf_XjcO4d4wdy0vjUy7F1goxolp4U1exTchQ,
  plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_0LdmbEwaZlmez_pLMjoiNzaQjnu46_jB7rmnKP9lUE4,
  plugin_NcviW38rpSD2IsuLJzzNVEF2WiSFKuUcUjSV5jfasuQ,
  preview_client_M4MFWlizSatyVIUowK4VJc1Ur_7uSp9S9M3HGzLMZzc,
  siteConfig_HWRVSH8PoXdN2sdoM1aq6aAH9RFTjnDvOsJRDHPHtaA,
  inferSeoMetaPlugin_6VdRUTP2HRvZp7dLS0vD5lQKXygcaJRDdLEGeassXfI,
  titles_qhTD2pN7QljOO0ZbjV5Hs9i5ciO4An4563f8rf4gpCk,
  _0_routeRules_1MFZdZ2iCDne8e_P5mMTA8GbtN42x2s_44JFwLQXgb8,
  defaults_KNAcQuIU9c6xfB_Yvxb9204wHbUsE_FRV4nziaeJ_o0
]